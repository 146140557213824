import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  firstName: "",
  lastName: "",
  giftsChoosen: [],
  giftCheckout: [],
  giftsVariant: [],
  checkoutEnabled: false
};

const chooseGiftsSlice = createSlice({
  name: "chooseGifts",
  initialState,
  reducers: {
    addFirstName: (state, action) => {
      state.firstName = action.payload;
    },
    addLastName: (state, action) => {
      state.lastName = action.payload;
    },
    addGiftsChoosen: (state, action) => {
      const newGifts = action.payload;
      state.giftsChoosen = newGifts;
    },
    addGiftCheckout: (state, action) => {
      state.giftCheckout = action.payload;
    },
    addGiftVariant: (state, action) => {
      const newVariant = action.payload;
      state.giftsVariant = newVariant;
    },
    enableCheckout: (state, action) => {
      return {
        ...state,
        checkoutEnabled: true
      };
    }
  }
});

export const {
  addGiftsChoosen,
  addFirstName,
  addLastName,
  addGiftCheckout,
  addGiftVariant,
  enableCheckout
} = chooseGiftsSlice.actions;
export default chooseGiftsSlice.reducer;
