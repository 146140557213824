import React from "react";
import CampaignExpiredImg from "../../assets/images/CampaignClaimed.svg";

function CampaignClaimed() {
  return (
    <div className="campaign_claimed">
      <img
        src={CampaignExpiredImg}
        alt="Campaign Expired"
        title="Campaign Expired"
      />
    </div>
  );
}

export default CampaignClaimed;
