import React from "react";
import { Outlet, Navigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const CheckoutRoute = () => {
  const { camp_id, id } = useParams();
  const checkoutEnabled = useSelector(
    (state) => state.chooseGifts.checkoutEnabled
  );

  return checkoutEnabled ? (
    <Outlet />
  ) : (
    <Navigate to={`/choose-my-gift/${camp_id}/${id}/select-gifts`} />
  );
};

export default CheckoutRoute;
