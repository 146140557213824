import React from "react";
import "./ChooseGift.scss";

const AddressHeader = () => {
  return (
    <div className="gift_header">
      <div className="gift_header_container">
        <div className="gift_header_title_container">
          <h3
            className="__header_address_h3"
            style={{
              color: "#FFFFFF",
              fontFamily: "DM Sans",
              fontStyle: "normal",
              fontWeight: "400"
            }}
          >
            CONGRATS 🎉 Your gift is on its way.
          </h3>
        </div>
      </div>
    </div>
  );
};

export default AddressHeader;
