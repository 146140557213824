import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "../../api_conf/BaseUrl";
import { useDispatch } from "react-redux";
import GiftShowcase from "./GiftShowcase";
import GiftHeader from "./GiftHeader";
import GiftLoader from "./GiftLoader";
import {
  addGiftVariant,
  addGiftsChoosen,
  addFirstName,
  addLastName
} from "../../store/chooseGifts";

import CampaignClaimed from "./CampaignClaimed";
import { useMediaQuery } from "@mantine/hooks";
import "./ChooseGift.scss";

const GiftSection = ({ multiSelect = false }) => {
  const [gifts, setGifts] = useState([]);
  const [logo, setLogo] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [selection, setSelection] = useState([]);
  const [notClaimed, setNotClaimed] = useState(false);
  const [showcaseItem, setShowcaseItem] = useState([]);
  const [showGiftInfo, setShowGiftInfo] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, camp_id } = useParams();
  const giftsChoosen = useSelector((state) => state.chooseGifts.giftsChoosen);
  const giftsVariant = useSelector((state) => state.chooseGifts.giftsVariant);
  const matches = useMediaQuery("(min-width: 950px)");

  const handleAddGifts = (item) => {
    dispatch(addGiftVariant([]));
    if (!giftsChoosen.some((currElem) => currElem.id === item.id)) {
      if (!multiSelect) {
        dispatch(addGiftsChoosen([item]));
      } else if (multiSelect) {
        dispatch(addGiftsChoosen([...giftsChoosen, item]));
      }
    } else {
      let giftAfterRemoval = giftsChoosen;
      giftAfterRemoval = giftAfterRemoval.filter(
        (currElem) => currElem.id !== item.id
      );
      dispatch(addGiftsChoosen([...giftAfterRemoval]));
    }
  };

  const giftsInSelection = (item) => {
    if (giftsChoosen.find((currElem) => currElem.id === item.id)) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
  }, []);

  useEffect(() => {
    window.scroll(0, 0);
    setLoading(true);
    setError(false);
    const chooseGifts = async () => {
      try {
        const res = await axios.post("choosegift/", {
          gift_key: id
        });

        if (res?.data?.success) {
          setLoading(false);
          setGifts(res?.data?.response);
          setNotClaimed(res?.data?.is_claimed);
          dispatch(addFirstName(res?.data?.user_first_name));
          dispatch(addLastName(res?.data?.user_last_name));
          setLogo(res?.data?.logo_image_url);
        } else {
          setNotClaimed(res?.data?.is_claimed);
        }
      } catch (error) {
        console.log(error);
        if (error.response) {
          toast.error(error.response.data.message);
          setError(true);
        } else if (error.request) {
        } else {
          toast.error(error);
        }
      }
    };

    chooseGifts();
  }, []);

  const handleShowcase = (item) => {
    setShowcaseItem(item);
    setShowGiftInfo(true);
    document.body.style.overflow = "hidden";
  };

  return (
    <>
      {notClaimed ? (
        <CampaignClaimed />
      ) : (
        <>
          <GiftHeader showGiftInfo={showGiftInfo} />
          <div className="gift_section_wrapper">
            {showGiftInfo && (
              <GiftShowcase
                selection={selection}
                setSelection={setSelection}
                handleAddGifts={handleAddGifts}
                showcaseItem={showcaseItem}
                giftsInSelection={giftsInSelection}
                setShowGiftInfo={setShowGiftInfo}
              />
            )}
            {showGiftInfo ? (
              ""
            ) : (
              <div className="instruction">
                Click on a gift you are interested in to read more and select
                it.
              </div>
            )}
            <div className="gift_section_navigation"> </div>
            <div className={loading && !error ? "" : "gift_section_container"}>
              {error ? (
                <div className="choose_gift_section_error">
                  <h6>Invalid gift key</h6>
                </div>
              ) : loading ? (
                <GiftLoader />
              ) : (
                gifts?.map((currElem) => {
                  return (
                    <div key={currElem.id} className="gift_section_content">
                      <div className="gift_section_image_outer">
                        <img
                          className="gift_section_content_image"
                          src={currElem.img_url}
                          alt={currElem.name}
                          onClick={() => handleShowcase(currElem)}
                        />

                        <div
                          className="gift_section_content_title"
                          onClick={() => handleShowcase(currElem)}
                        >
                          <p>{currElem.name}</p>
                          <p>{currElem.vendor}</p>
                        </div>
                      </div>
                      {matches ? (
                        ""
                      ) : (
                        <div className="choose_gift_section_info">
                          {currElem.name.length > 14 ? (
                            <p className="choose_gift_section_info_name">
                              {`${currElem.name.slice(0, 14)}...`}
                              <span className="choose_gift_section_name_tooltip">
                                {currElem.name}
                              </span>
                            </p>
                          ) : (
                            <p>{currElem.name}</p>
                          )}
                          <p>{currElem.vendor}</p>
                        </div>
                      )}
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default GiftSection;
