import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import GiftCheckoutHeader from "./GiftCheckoutHeader";
import { useWindowSize } from "@react-hook/window-size";
import Confetti from "react-confetti";

const GiftCheckout = () => {
  const giftCheckout = useSelector((state) => state.chooseGifts.giftCheckout);
  const [width, height] = useWindowSize();
  const [conf,setConf] = useState(true);

  useEffect(() => {
    window.scroll(0,0);
    setTimeout(() => {
      setConf(false);
    },8000)
  },[])
  return (
    <>
      <GiftCheckoutHeader />
      <Confetti
        width={`${width}px`}
        height={`${height}px`}
        gravity={0.07}
        numberOfPieces={300}
        recycle={conf}
      />
      <div
        style={{ marginTop: `${height / 4}px` }}
        className="checkout_content_container"
      >
        <h3 className="checkout_content_h3">
          Are you smiling,{" "}
          <span style={{ textTransform: "capitalize" }}>
            {giftCheckout.user_first_name}
          </span>
          ? You’re definitely smiling.
        </h3>
        <h6 className="checkout_content_h6">
          Order details will be sent to your email,{" "}
          <span style={{ textTransform: "capitalize" }}>
            {giftCheckout.user_first_name}
          </span>
          . Expect your gift within 10 business days.
        </h6>
      </div>
    </>
  );
};

export default GiftCheckout;

