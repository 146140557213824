import React from "react";
import { Outlet, Navigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const GiftRoute = () => {
  const { camp_id, id } = useParams();
  const giftsChoosen = useSelector((state) => state.chooseGifts.giftsChoosen);
  const giftsVariant = useSelector((state) => state.chooseGifts.giftsVariant);

  return giftsChoosen[0]?.id !== undefined ||
    giftsVariant[0]?.id !== undefined ? (
    <Outlet />
  ) : (
    <Navigate to={`/choose-my-gift/${camp_id}/${id}/select-gifts`} />
  );
};

export default GiftRoute;
