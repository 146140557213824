import axios from "axios";

const instance = axios.create({
  // baseURL: "http://103.46.239.133:8011/",
  // devMediaUrl: "http://103.46.239.133:8011",
   baseURL: "https://backend.heykaido.com/",
   devMediaUrl: "https://backend.heykaido.com"
});

export default instance;
