import React, { useEffect, useState } from "react";
import axios from "../../api_conf/BaseUrl";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addGiftCheckout, enableCheckout } from "../../store/chooseGifts";
import AddressHeader from "./AddressHeader";
import LoadingSpinner from "../../assets/images/giftIcon1.gif";
import { Typography } from "@mui/material";
import { Country, State, City } from "country-state-city";

const ChooseGiftAddress = () => {
  const navigate = useNavigate();
  const { id, camp_id } = useParams();
  const dispatch = useDispatch();
  const [isDisabled, setIsDisabled] = useState(true);
  const giftsChoosen = useSelector((state) => state.chooseGifts.giftsChoosen);
  const giftsVariant = useSelector((state) => state.chooseGifts.giftsVariant);
  const variant = giftsChoosen.map((item) => item.variant_details);
  const [loading, setLoading] = useState(false);
  const giftId = giftsChoosen.map((item) => item.id);
  const giftName = giftsChoosen.map((item) => item.name);
  const giftPrice = giftsChoosen.map((item) => item.price);
  const variantId = variant[0]?.map((item) => item.id);

  const [country, setCountry] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    address1: "",
    address2: "",
    city: "",
    province: "",
    country: "",
    zip: "",
    thankyouNote: ""
  });

  useEffect(() => {
    document.body.style.overflow = "visible";
  }, []);

  useEffect(() => {
    validateAddress();
    getState();
  }, [formData]);

  useEffect(() => {
    window.scroll(0, 0);
    setCountry(Country.getAllCountries());
  }, []);

  const getState = async () => {
    country.map((e) => {
      if (e.name == formData.country) {
        setStateList(State.getStatesOfCountry(e.isoCode));
      }
    });
  };
  useEffect(() => {
    stateList.map((e) => {
      if (e.name == formData.province) {
        setCityList(City.getCitiesOfState(e.countryCode, e.isoCode));
      }
    });
  }, [stateList]);

  const validateAddress = () => {
    if (
      formData.first_name !== "" &&
      formData.last_name !== "" &&
      formData.email !== "" &&
      formData.address1 !== "" &&
      formData.city !== "" &&
      formData.province !== "" &&
      formData.country !== "" &&
      formData.zip !== ""
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const res = await axios.post("ordergift/", {
        product_id:
          giftsChoosen.length > 0
            ? giftId[0].toString()
            : giftsVariant[0]?.product_id,
        variant_id:
          giftsChoosen.length > 0
            ? variantId[0].toString()
            : giftsVariant[0]?.id,
        campaign_id: parseInt(camp_id),
        email: formData.email,
        send_receipt: false,
        send_fulfillment_receipt: false,
        fulfillment_status: null,
        first_name: formData.first_name,
        last_name: formData.last_name,
        address1: formData.address1,
        address2: formData.address2,
        phone: "",
        city: formData.city,
        province: formData.province,
        country: formData.country,
        zip: formData.zip,
        product_name:
          giftsChoosen.length > 0
            ? giftName[0].toString()
            : giftsVariant[0]?.title,
        product_price:
          giftsChoosen.length > 0
            ? parseFloat(giftPrice[0])
            : parseFloat(giftsVariant[0]?.price),
        product_quantity: 1,
        thankyou_note: formData.thankyouNote,
        gift_key: id
      });

      dispatch(enableCheckout());
      dispatch(addGiftCheckout(res.data));

      if (res?.data?.success) {
        setTimeout(() => {
          navigate(
            `/choose-my-gift/${camp_id}/${id}/select-gifts/recepient-info/thank-you`
          );
          setLoading(false);
        }, 1000);
      } else {
        toast.error(res.data.message);
        setLoading(false);
      }
    } catch (error) {
      if (error.response) {
        if (
          error.response.data.message ===
          "Exception here!'NoneType' object is not subscriptable"
        ) {
          toast.error("Enter Valid Email");
          setLoading(false);
        } else {
          toast.error(error.response.data.message);
          setLoading(false);
        }
      } else if (error.request) {
        setLoading(false);
      } else {
        toast.error(error);
        setLoading(false);
      }
      setLoading(false);
    }
  };

  return (
    <>
      <AddressHeader />
      {loading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            width: "100vw"
          }}
        >
          <img
            style={{ width: "55px", height: "55px" }}
            src={LoadingSpinner}
            alt="Loading..."
          />
        </div>
      ) : (
        <>
          <div className="address_container">
            <Typography
              sx={{
                fontSize: {
                  lg: 30,
                  md: 20,
                  sm: 15,
                  xs: 10
                },
                color: "#3D3C3C",
                fontFamily: "DM Sans",
                fontWeight: "700",
                margin: "20px 0px"
              }}
            >
              Where should we send your 🎁 ?
            </Typography>
            <div className="row_fields">
              <div>
                <label className="input_label">FIRST NAME*</label>
                <input
                  className="equal_input"
                  placeholder="First Name"
                  value={formData.first_name}
                  onChange={(event) => {
                    setFormData({
                      ...formData,
                      first_name: event.target.value
                    });
                  }}
                ></input>
              </div>

              <div>
                <label className="input_label">LAST NAME*</label>
                <input
                  className="equal_input"
                  placeholder="Last Name"
                  value={formData.last_name}
                  onChange={(event) => {
                    setFormData({
                      ...formData,
                      last_name: event.target.value
                    });
                  }}
                ></input>
              </div>

              <div>
                <label className="input_label">Email*</label>
                <input
                  className="equal_input"
                  placeholder="Enter Email"
                  value={formData.email}
                  onChange={(event) => {
                    setFormData({
                      ...formData,
                      email: event.target.value
                    });
                  }}
                ></input>
              </div>
            </div>
            <div className="row_fields">
              <div>
                <label className="input_label">COUNTRY*</label>
                <input
                  className="equal_input"
                  placeholder="Enter Country"
                  value={formData.country}
                  onChange={(event) => {
                    setFormData({
                      ...formData,
                      country: event.target.value
                    });
                  }}
                ></input>
              </div>

              <div>
                <label className="input_label">STATE*</label>
                <input
                  className="equal_input"
                  placeholder="Enter State"
                  value={formData.province}
                  onChange={(event) => {
                    setFormData({
                      ...formData,
                      province: event.target.value
                    });
                  }}
                ></input>
              </div>
              <div>
                <label className="input_label">CITY*</label>
                <input
                  className="equal_input"
                  placeholder="Enter City"
                  value={formData.city}
                  onChange={(event) => {
                    setFormData({
                      ...formData,
                      city: event.target.value
                    });
                  }}
                ></input>
              </div>
            </div>
            <div className="row_fields">
              <div>
                <label className="input_label">SHIPPING ADDRESS*</label>
                <input
                  className="equal_input_address"
                  placeholder="Street Address "
                  value={formData.address1}
                  onChange={(event) => {
                    setFormData({
                      ...formData,
                      address1: event.target.value
                    });
                  }}
                ></input>
              </div>

              <div>
                <label className="input_label">APT.</label>
                <input
                  className="equal_input_apt"
                  placeholder="Enter Apt/Suite #"
                  value={formData.address2}
                  onChange={(event) => {
                    setFormData({
                      ...formData,
                      address2: event.target.value
                    });
                  }}
                ></input>
              </div>

              <div>
                <label className="input_label">ZIP CODE*</label>
                <input
                  className="equal_input_zip"
                  placeholder="Enter Zip Code"
                  value={formData.zip}
                  onChange={(event) => {
                    setFormData({
                      ...formData,
                      zip: event.target.value
                    });
                  }}
                ></input>
              </div>
            </div>
            <div className="row_fields">
              <div>
                <label className="input_label">WANT TO SAY THANK YOU?</label>
                <textarea
                  className="text_thank_you"
                  placeholder="Personalize a thank you note (optional)"
                  value={formData.thankyouNote}
                  onChange={(event) => {
                    setFormData({
                      ...formData,
                      thankyouNote: event.target.value
                    });
                  }}
                />
              </div>
            </div>
            <div>
              <button
                className="get_my_gift_btn"
                onClick={handleSubmit}
                disabled={isDisabled}
                style={{ opacity: isDisabled ? "0.5" : "" }}
              >
                Get my gift!
              </button>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ChooseGiftAddress;
