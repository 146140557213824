import React from "react";
import { Routes, Route } from "react-router-dom";

import ChooseGift from "./Pages/ChooseGifts/ChooseGift";
import ChooseGiftAddress from "./Pages/ChooseGifts/ChooseGiftAddress";
import GiftSection from "./Pages/ChooseGifts/GiftSection";
import GiftCheckout from "./Pages/ChooseGifts/GiftCheckout";
import NotFound from "./Pages/NotFound/NotFound";

import GiftRoute from "./utils/GiftRoute";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import CheckoutRoute from "./utils/CheckoutRoute";

export default function App() {
  return (
    <div className="App">
      <ToastContainer
        position="top-right"
        autoClose={2500}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick={true}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <>
        <Routes>
          <Route
            path="/choose-my-gift/:camp_id/:id/select-gifts"
            element={<GiftSection />}
          />

          <Route element={<GiftRoute />}>
            <Route
              path="/choose-my-gift/:camp_id/:id/select-gifts/recepient-info"
              element={<ChooseGiftAddress />}
              exact
            />
          </Route>

          <Route element={<CheckoutRoute />}>
            <Route
              path="/choose-my-gift/:camp_id/:id/select-gifts/recepient-info/thank-you"
              element={<GiftCheckout />}
              exact
            />
          </Route>

          <Route path="/choose-my-gift/:camp_id/:id" element={<ChooseGift />} />

          <Route path="*" element={<NotFound />} />
        </Routes>
      </>
    </div>
  );
}
