import React, { useState, useRef } from "react";
import CloseIcon from "../../assets/images/lightclose.svg";
import { useDispatch, useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { useParams, useNavigate } from "react-router-dom";
import { addGiftsChoosen, addGiftVariant } from "../../store/chooseGifts";
import { toast } from "react-toastify";

import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/scrollbar/scrollbar.scss";
import { useEffect } from "react";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

const GiftShowcase = ({
  showcaseItem,
  setShowGiftInfo,
  multiSelect = false,
  handleAddGifts
}) => {
  const { id, camp_id } = useParams();
  const variantRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showTags, setShowTags] = useState(true);
  const giftsVariant = useSelector((state) => state.chooseGifts.giftsVariant);

  const [currentImage, setCurrentImage] = useState(showcaseItem.img_url);
  const handleShowcaseImage = (item) => {
    setCurrentImage(item);
  };

  const [variant_button, setVariantButton] = useState(false);

  const handleAddVariants = (item) => {
    setCurrentImage(item.image_url);
    dispatch(addGiftsChoosen([]));
    if (!giftsVariant.some((currElem) => currElem.id === item.id)) {
      setVariantButton(true);
      if (!multiSelect) {
        dispatch(addGiftVariant([item]));
      } else if (multiSelect) {
        dispatch(addGiftVariant([...giftsVariant, item]));
      }
    } else {
      setVariantButton(false);
      let giftAfterRemoval = giftsVariant;
      giftAfterRemoval = giftAfterRemoval.filter(
        (currElem) => currElem.id !== item.id
      );
      dispatch(addGiftVariant([...giftAfterRemoval]));
    }
  };

  const variatnInSelection = (item) => {
    if (giftsVariant.find((currElem) => currElem.id === item.id)) {
      return true;
    }

    return false;
  };

  let variants = [
    "female-founded",
    "bipoc-owned",
    "lgbtq-owned",
    "gives back",
    "sustainable"
  ];

  useEffect(() => {
    if (variantRef?.current?.style?.display !== "block") {
      setShowTags(false);
    } else {
      setShowTags(true);
    }
  }, [showcaseItem?.tages?.length]);

  useEffect(() => {
    showcaseItem?.variant_details.length > 1
      ? setVariantButton(false)
      : setVariantButton(true);
  }, []);

  return (
    <div className="gift_showcase_container">
      <div className="gift_showcase">
        <div style={{ display: "flex", height: "86%" }}>
          <div className="gift_showcase_left">
            <div className="gift_showcase_left_image">
              <div className="gift_showcase_left_image_slider_container">
                {showcaseItem?.images_url.length > 1 ? (
                  <Swiper spaceBetween={-20} slidesPerView={4} navigation>
                    {showcaseItem?.images_url?.map((item, index) => {
                      return (
                        <SwiperSlide key={index}>
                          <div className="gift_showcase_left_image_slider">
                            <img
                              src={item}
                              alt="Slider images"
                              onClick={() => handleShowcaseImage(item)}
                            />
                          </div>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="gift_showcase_image_main">
              <img src={currentImage} alt={showcaseItem.name} />
            </div>

            <div className="gift_showcase_brand_name">
              <h3>
                By <span>{showcaseItem.vendor}</span>
              </h3>
            </div>
            <div className="gift_showcase_product_name">
              <h3>{showcaseItem.name}</h3>
            </div>
          </div>
          <div className="gift_showcase_right">
            <div className="gift_showcase_right_close_btn">
              <img
                src={CloseIcon}
                onClick={() => {
                  setShowGiftInfo(false);
                  document.body.style.overflow = "visible";
                }}
              />
            </div>
            <div className="gift_showcase_right_info">
              <div className="gift_showcase_left_image_slider_container">
                {showcaseItem?.images_url.length > 1 ? (
                  <Swiper
                    pagination={{ dynamicBullets: true }}
                    modules={[Pagination]}
                  >
                    {showcaseItem?.images_url?.map((item, index) => {
                      return (
                        <SwiperSlide
                          key={index}
                          pagination={{ dynamicBullets: true }}
                        >
                          <div className="gift_showcase_left_image_slider_mb">
                            <div className="gift_showcase_product_name">
                              <h3>{showcaseItem.name}</h3>
                            </div>
                            <div className="gift_showcase_brand_name">
                              <h3>
                                By <span>{showcaseItem.vendor}</span>
                              </h3>
                            </div>
                            <img
                              src={item}
                              alt="Slider images"
                              onClick={() => handleShowcaseImage(item)}
                            />
                          </div>
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                ) : (
                  ""
                )}
              </div>

              <div
                className="gift_showcase_tags_container"
                style={{ display: showTags ? "flex" : "none" }}
              >
                {showcaseItem?.tages?.map((el) => {
                  const variantData = variants.includes(el.toLowerCase());
                  return el.length && variantData ? (
                    <div
                      ref={variantRef}
                      className="gift_showcase_tags"
                      style={{
                        display: el?.length && variantData ? "block" : "none"
                      }}
                    >
                      {el}
                    </div>
                  ) : (
                    ""
                  );
                })}
              </div>

              <div
                className="gift_showcase_product_description"
                style={{ height: !showTags && "70%" }}
              >
                <h3
                  style={{
                    fontFamily: "DM Sans",
                    fontStyle: "normal",
                    fontWeight: "500",
                    fontSize: "14px",
                    lineHeight: "18px",
                    color: "#1D1D1D",
                    textAlign: "left"
                  }}
                >
                  Gift Description
                </h3>
                <p className="gift_showcase_product_description_content">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: showcaseItem?.description || ""
                    }}
                  ></div>
                </p>
              </div>

              {showcaseItem.variant_details.length > 1 ? (
                <div className="gift_showcase_product_info">
                  <h3>Personalize*</h3>
                  <p>
                    Please choose your preferred variant. We’ll do our best to
                    fulfill it!
                  </p>
                </div>
              ) : (
                ""
              )}

              <div
                className="gift_showcase_product_variant"
                style={{
                  display:
                    showcaseItem?.variant_details.length > 1 ? "flex" : "none",
                  marginTop: "2%"
                }}
              >
                {showcaseItem?.variant_details.map((currElem) => {
                  return (
                    <span
                      style={{
                        backgroundColor:
                          variatnInSelection(currElem) && "#219ebc",
                        border: "1.3px solid #219EBC",
                        borderRadius: "2px"
                      }}
                      onClick={() => handleAddVariants(currElem)}
                    >
                      <p
                        style={{
                          fontFamily: "DM Sans",
                          fontStyle: "normal",
                          fontWeight: "400",
                          fontSize: "12px",
                          color: variatnInSelection(currElem)
                            ? "#FFFFFF"
                            : "#219EBC "
                        }}
                      >
                        {currElem.title}
                      </p>
                    </span>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        {showcaseItem.variant_details.length <= 1 ? (
          <div
            className="gift_showcase_product_add_btn_container"
            style={{
              background: "#219EBC",
              border: "1px solid rgba(33, 158, 188, 0.7)",
              borderRadius: "2px",
              color: "#FFFFFF",
              width: "90%",
              height: "45px",
              margin: "auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer"
            }}
            onClick={() => {
              handleAddGifts(showcaseItem);
              navigate(
                `/choose-my-gift/${camp_id}/${id}/select-gifts/recepient-info`
              );
              document.body.style.overflow = "visible";
            }}
          >
            <button
              className="gift_showcase_product_add_btn_main"
              style={{
                background: "transparent",
                border: "none",
                outline: "none",
                color: "#ffffff",
                fontFamily: "DM Sans",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "18px",
                cursor: "pointer"
              }}
            >
              <div style={{ cursor: "pointer" }}>I want this gift</div>
            </button>
          </div>
        ) : (
          <div
            className="gift_showcase_product_add_btn_container"
            style={{
              background: "#219EBC",
              border: "1px solid rgba(33, 158, 188, 0.7)",
              borderRadius: "2px",
              color: "#FFFFFF",
              width: "90%",
              height: "45px",
              margin: "auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              opacity: variant_button ? "" : "0.5"
            }}
            onClick={() => {
              if (variant_button) {
                navigate(
                  `/choose-my-gift/${camp_id}/${id}/select-gifts/recepient-info`
                );
                document.body.style.overflow = "visible";
              } else {
                toast.warn("Please select variant");
              }
            }}
          >
            <button
              className="gift_showcase_product_add_btn_main"
              style={{
                pointerEvents: variant_button ? "none" : "fill",
                background: "transparent",
                border: "none",
                outline: "none",
                color: "#ffffff",
                fontFamily: "DM Sans",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "18px"
              }}
            >
              <div>I want this gift</div>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default GiftShowcase;
