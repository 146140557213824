import React, { useEffect } from "react";

import "./ChooseGift.scss";
import loader from "../../assets/images/giftIcon1.gif";

const GiftLoader = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="select_gift_loader_container">
      <img
        style={{ width: "50px", height: "50px" }}
        src={loader}
        alt="loading..."
      />
    </div>
  );
};

export default GiftLoader;
