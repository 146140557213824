import React from "react";
import { useSelector } from "react-redux";

const GiftHeader = () => {
  const first_name = useSelector((state) => state.chooseGifts.firstName);

  return (
    <div className="gift_header">
      <div className="gift_header_container">
        <div className="gift_header_title_container">
          <h4 className="gift_header_title">{`Choose your favorite 🎁 , ${first_name}!`}</h4>
        </div>
      </div>
    </div>
  );
};

export default GiftHeader;
