import React, { useState, useEffect } from "react";
import axios from "../../api_conf/BaseUrl";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CampaignExpired from "./CampaignExpired";
import CampaignClaimed from "./CampaignClaimed";

import "./ChooseGift.scss";
import "./DesignTemplate.scss";

const ChooseGift = () => {
  const { camp_id, id } = useParams();
  const [logo, setLogo] = useState([]);
  const [logoSelected, setLogoSelected] = useState(false);
  const [error, setError] = useState(false);
  const [notClaimed, setNotClaimed] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [template, setTemplate] = useState([]);
  const navigate = useNavigate();

  const getDesignCard = async () => {
    setLoading(true);
    try {
      const res = await axios.post(`designcard/?campaign_id=${camp_id}`, {
        gift_key: id
      });
      if (res?.data?.success) {
        setLogo(res?.data?.logo_url);
        setTemplate(res?.data?.design_card_html);
        setLogoSelected(res?.data?.is_logo);
        setNotClaimed(res?.data?.is_claimed);
        setLoading(false);
      } else {
        setNotClaimed(res?.data?.is_claimed);
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        setErrorMessage(error.response.data.message);
        setError(true);
      } else if (error.request) {
      } else {
        toast.error(error);
      }
    }
  };

  const handleClickNext = () => {
    navigate(`/choose-my-gift/${camp_id}/${id}/select-gifts`);
  };

  useEffect(() => {
    getDesignCard();
  }, []);

  useEffect(() => {
    document.ontouchmove = function (e) {
      e.preventDefault();
    };
  }, []);

  return (
    <>
      {error ? (
        <div className="choose_gift_template_error">
          <CampaignExpired />
        </div>
      ) : notClaimed ? (
        <CampaignClaimed />
      ) : (
        <div className="choose_gift_wrapper">
          <div dangerouslySetInnerHTML={{ __html: template || "" }}></div>
          {logo.length > 0 ? (
            <div className="choose_gift_logo_selected">
              {logoSelected && (
                <img src={axios.defaults.devMediaUrl + logo} alt="logo" />
              )}
            </div>
          ) : (
            ""
          )}
          <div className="choose_gift_btn_container">
            <div
              className="choose_gift"
              onClick={() => {
                handleClickNext();
              }}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItem: "center",
                cursor: "pointer"
              }}
            >
              Choose my gift!
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ChooseGift;
