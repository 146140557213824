import { Typography } from "@mui/material";
import React from "react";

const AddressHeader = () => {
  return (
    <div className="gift_header">
      <div className="gift_header_container ">
        <div className="gift_header_title_container">
          <Typography
            sx={{
              fontSize: {
                lg: 40,
                md: 30,
                sm: 25,
                xs: 15
              },
              fontFamily: "DM Sans",
              fontStyle: "normal",
              fontWeight: "400",
              color: "#FFFFFF"
            }}
          >
            What a 🔥 pick! Just a couple more things...
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default AddressHeader;
