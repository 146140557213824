import React from "react";
import CampaignExpiredImg from "../../assets/images/CampaignExpired.svg";

function CampaignExpired() {
  return (
    <div className="campaign_expired">
      <img
        src={CampaignExpiredImg}
        alt="Campaign Expired"
        title="Campaign Expired"
      />
    </div>
  );
}

export default CampaignExpired;
